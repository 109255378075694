import { PERSONA_ITEMS, USER_ACCESS_FEATURES } from "@/components/constants.js"
import { Connector } from "@evercam/shared/types"
const connectors = [
  { name: "All", value: "" },
  { name: "Aconex", value: Connector.Aconex },
  { name: "Procore", value: Connector.Procore },
  { name: "Autodesk", value: Connector.Autodesk },
  { name: "Autodesk Viewer", value: Connector.AutodeskViewer },
  { name: "Voyage Control", value: Connector.VoyageControl },
]
export default (self) => {
  return {
    fullname: {
      component: "TextFieldSearchFilter",
    },
    integrations: {
      component: "SelectSearchFilter",
      attributes: {
        items: connectors,
      },
    },
    persona: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...PERSONA_ITEMS],
        value: "",
      },
    },
    companyName: {
      component: "TextFieldSearchFilter",
      attributes: {
        showToolTip: true,
        toolTipText: "You can use (=null) to filter users with no company",
      },
    },
    countryName: {
      component: "TextFieldSearchFilter",
    },
    email: {
      component: "TextFieldSearchFilter",
    },
    lastEventName: {
      component: "TextFieldSearchFilter",
    },
    lastEventOs: {
      component: "TextFieldSearchFilter",
    },
    lastEventBrowser: {
      component: "TextFieldSearchFilter",
    },
    eventsCount: {
      component: "CountFieldSearchFilter",
      listeners: {
        keypress: (e) =>
          self.allowKeypress(e, { comparison: true, number: true }),
      },
    },
    camerasCount: {
      component: "CountFieldSearchFilter",
      listeners: {
        keypress: (e) =>
          self.allowKeypress(e, { comparison: true, number: true }),
      },
    },
    projectsCount: {
      component: "CountFieldSearchFilter",
      listeners: {
        keypress: (e) =>
          self.allowKeypress(e, { comparison: true, number: true }),
      },
    },
    camerasOwned: {
      component: "CountFieldSearchFilter",
      listeners: {
        keypress: (e) =>
          self.allowKeypress(e, { comparison: true, number: true }),
      },
    },
    cameraShares: {
      component: "CountFieldSearchFilter",
      listeners: {
        keypress: (e) =>
          self.allowKeypress(e, { comparison: true, number: true }),
      },
    },
    snapmailCount: {
      component: "CountFieldSearchFilter",
      listeners: {
        keypress: (e) =>
          self.allowKeypress(e, { comparison: true, number: true }),
      },
      isCountField: true,
    },
    sessionCount: {
      component: "CountFieldSearchFilter",
      listeners: {
        keypress: (e) =>
          self.allowKeypress(e, { comparison: true, number: true }),
      },
    },
    createdAt: {
      component: "DatePickerSearchFilter",
      name: "createdAtDate",
      attributes: {
        textFieldAttrs: {
          placeholder: "Less than",
        },
        convertToIso: true,
      },
    },
    lastLoginAt: {
      component: "DatePickerSearchFilter",
      name: "lastLoginAtDate",
      attributes: {
        textFieldAttrs: {
          placeholder: "Less than",
        },
        convertToIso: true,
      },
    },
    telephone: {
      component: "TextFieldSearchFilter",
    },
    features: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...USER_ACCESS_FEATURES],
        value: "",
      },
    },
  }
}
